import { char_to_key, char_to_code, char_to_keyCode } from "./keymaps.json";

const getKey = (char) => char_to_key[char];
const getCode = (char) => char_to_code[char];
const getKeyCode = (char) => char_to_keyCode[char];
const getRawButton = (button) =>
  button.includes("virtual") ? button[9] : button.slice(1, -1);

export const getKeyboardEventOptions = (button) => {
  const raw_button = getRawButton(button);
  return {
    bubbles: true,
    cancelable: true,
    key: getKey(raw_button),
    code: getCode(raw_button),
    keyCode: getKeyCode(raw_button),
  };
};
