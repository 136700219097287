<template>
  <div class="keyboardContainer" id="keyboard">
    <div class="simple-keyboard-main" id="keyboard-main"></div>
  </div>
</template>

<script>
import Keyboard from "simple-keyboard";
import { getKeyboardEventOptions } from "./handleKey";
import { layout, display } from "./keyboardConfig";

export default {
  name: "SimpleKeyboard",
  data: () => ({
    keyboard: null,
  }),
  mounted() {
    const config = {
      onKeyPress: (button) => this.onKeyPress(button),
      onKeyReleased: (button) => this.onKeyReleased(button),
      theme: "simple-keyboard hg-theme-default hg-layout-default",
      mergeDisplay: true,
      disableCaretPositioning: true,
      debug: false,
      layout: layout,
      display: display,
    };

    this.keyboard = new Keyboard(".simple-keyboard-main", config);
  },
  methods: {
    onKeyPress(button) {
      if (button === "{functions}") {
        this.handleFunctions();
      } else if (button === "{exit}") {
        this.$emit("closeKeyboard");
      } else {
        const ke_options = getKeyboardEventOptions(button);
        const ke = new KeyboardEvent("keydown", ke_options);
        this.$emit("onKeyPress", ke);
        this.keyboard.clearInput();
      }
    },
    onKeyReleased(button) {
      if (button !== "{functions}") {
        const ke_options = getKeyboardEventOptions(button);
        const ke = new KeyboardEvent("keyup", ke_options);
        this.$emit("onKeyReleased", ke);
        this.keyboard.clearInput();
      }
    },
    handleFunctions() {
      const currentLayout = this.keyboard.options.layoutName;
      this.keyboard.setOptions({
        layoutName: currentLayout === "functions" ? "default" : "functions",
      });
    },
  },
};
</script>

<style lang="scss">
#keyboard {
  @import "simple-keyboard/build/css/index";

  @media (prefers-color-scheme: dark) {
    @import "@/styles/_variables.scss";

    .hg-theme-default {
      background-color: $gray-900;

      .hg-button {
        background-color: $gray-800;
        border-bottom: 1px solid $black;
      }
    }
  }

  // stick to bottom
  .simple-keyboard {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 100;
  }
}
</style>