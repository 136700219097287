const layout = {
  default: [
    "1 2 3 4 5 6 7 8 9 0",
    "Q W E R T Y U I O P",
    "A S D F G H J K L",
    "{Shift} Z X C V B N M {Backspace}",
    "{functions} {Ctrl} {Alt} ↑ {Enter}",
    "{exit} {Space} ← ↓ →",
  ],
  functions: [
    "{Esc} {F1} {F2} {F3} {F4} {F5} {F6} {F7} {F8} {F9}",
    "{Tab} {F10} {Ins} {Home} {PageUp} [ ] \\",
    "{Caps_Lock} {F11} {Del} {End} {PageDown} ; '",
    "{Shift} {F12} ` - = , . /",
    "{functions} {Ctrl} {Alt} ↑ {Enter}",
    "{exit} {Space} ← ↓ →",
  ],
};

const display = {
  "{functions}": "Fn",
  "{Del}": "Del",
  "{Enter}": "Enter",
  "{Esc}": "Esc",
  "{Tab}": "Tab",
  "{Backspace}": "⌫",
  "{Space}": "⎵",
  "{Caps_Lock}": "Caps",
  "{Shift}": "⇧",
  "{Ctrl}": "Ctrl",
  "{Alt}": "Alt",
  "{Ins}": "Ins",
  "{Home}": "Home",
  "{PageUp}": "Up",
  "{PageDown}": "Down",
  "{End}": "End",
  "{F1}": "F1",
  "{F2}": "F2",
  "{F3}": "F3",
  "{F4}": "F4",
  "{F5}": "F5",
  "{F6}": "F6",
  "{F7}": "F7",
  "{F8}": "F8",
  "{F9}": "F9",
  "{F10}": "F10",
  "{F11}": "F11",
  "{F12}": "F12",
  "{exit}": "Exit",
};

const key_handler = (key) => {
  if (key.length === 1) {
    // It's a character
    const new_key = `{virtual_${key}}`;
    display[new_key] = key.toString();
    return new_key;
  } else {
    return key;
  }
};

// default and functions
for (const prop in layout) {
  layout[prop] = layout[prop].map((row) =>
    row.split(" ").map(key_handler).join(" ")
  );
}

export { layout, display };
